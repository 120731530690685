.button.w-inline-block {
  display: flex;
  justify-content: center;
}

#flex {
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
  padding-bottom: 10rem;
  justify-content: center;
}

body {
  font-family: var(--font-titillium-web), sans-serif;
  color: #22201d;
  font-size: 14px;
  line-height: 20px;
  background-color: #f5f0de;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 50px;
  line-height: 50px;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: var(--font-karla), sans-serif;
  font-size: 1.5vw;
  line-height: 2vw;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  background-color: transparent;
  font-family: var(--font-karla), sans-serif;
  font-size: 3vh;
  line-height: 3vh;
}

a {
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: #f13c20;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  text-decoration: none;
}

a:hover {
  color: #f13c20;
  text-decoration: none;
}

body a:hover {
  text-decoration: none !important;
}

ul {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 40px;
  font-family: var(--font-karla), sans-serif;
  font-size: 16px;
  line-height: 28px;
}

strong {
  font-weight: bold;
}

.button-hover {
  position: absolute;
  left: 0%;
  top: 2%;
  right: 0%;
  bottom: 0%;
  height: 95%;
  border-style: solid;
  border-width: 3px;
  border-color: #22201d;
  border-radius: 2px;
  background-color: #c5cbe3;
  -webkit-transform: skew(4deg, 0deg);
  -ms-transform: skew(4deg, 0deg);
  transform: skew(4deg, 0deg);
}

.button-text {
  position: relative;
  z-index: 2;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #22201d;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 3px;
  text-align: center;
  min-width: 200px;
}

.button-text:hover {
  color: #22201d;
}

.button-text.story {
  font-family: var(--font-titillium-web), sans-serif;
  color: #4056a1;
  font-size: 33px;
  font-weight: 900;
  width: 300px;
}

.button {
  background-image: none !important;
}
.button {
  position: relative;
  padding: 18px 30px;
  border-style: solid;
  border-width: 3px;
  border-color: #22201d;
  border-radius: 2px;
  color: #22201d;
  font-size: 16px;
  text-decoration: none;
}

.button:hover {
  color: #22201d;
  text-decoration: none;
}

.button._3 {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f13c20;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-family: var(--font-karla), sans-serif;
  color: #f7f0dc;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  text-transform: lowercase;
}

.button._3:hover {
  background-color: #f7f0dc;
  color: #22201d;
}

.button._3.contact-submit {
  position: relative;
  right: 0px;
  display: block;
  width: auto;
  margin-right: 0px;
  margin-left: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #4056a1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.button._3.contact-submit:hover {
  background-color: #c5cbe3;
}

.button._3.contact-submit.edit {
  width: auto;
}

.button.discover {
  margin-right: 20vw;
  margin-left: 20vw;
  justify-content: center;
  place-self: center;
}

.letter-bg-color {
  border-style: solid;
  border-width: 3px;
  border-color: #000 #000 #22201d;
  border-top-right-radius: 100px;
  background-color: #f5f0de;
  color: #d79922;
}

.letter-bg-color.red {
  padding-right: 8px;
  padding-left: 18px;
  border-top-left-radius: 100px;
  background-color: #f13c20;
  color: #f5f0de;
}

.letter-bg-color.red.title {
  position: relative;
  z-index: 1;
  padding-right: 10px;
  padding-left: 20px;
}

.letter-bg-color.gold {
  padding-right: 2px;
  padding-left: 9px;
  border-bottom-left-radius: 75px;
  background-color: #d79922;
  color: #f5f0de;
  text-align: left;
}

.letter-bg-color.gold.sign {
  padding-right: 25px;
  padding-left: 19px;
}

.letter-bg-color.blue {
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-right-radius: 100px;
  background-color: #4056a1;
  color: #c5cbe3;
}

.letter-bg-color.blue.title {
  position: relative;
  z-index: 0;
  font-size: 11vw;
  line-height: 11vw;
}

.letter-bg-color.blue.dash {
  padding-right: 13px;
  padding-left: 11px;
}

.letter-bg-color.title {
  padding-right: 10px;
  padding-left: 10px;
}

.align-center {
  text-align: center;
}

.cart {
  margin-right: 30px;
  margin-left: 30px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.form-field {
  height: 50px;
  margin-bottom: 18px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: #22201d;
  border-radius: 2px 25px;
  background-color: #f5f0de;
  font-family: var(--font-karla), sans-serif;
  color: #22201d;
}

.form-field:focus {
  border-color: #d19b52;
}

.contact-form {
  display: block;
  padding-top: 0px;
}

.form-field-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  font-family: var(--font-karla), sans-serif;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  /* display: flex; */
  /* -webkit-box-orient: vertical; */
  /* -webkit-box-direction: normal; */
  /* -webkit-flex-direction: column; */
  -ms-flex-direction: column;
  flex-direction: column;
  /* -webkit-box-pack: center; */
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  /* justify-content: center; */
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.contact-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  padding-top: 0px;
  align-items: center;
  flex-direction: column;
  /* -webkit-box-pack: center; */
  /* -webkit-justify-content: center; */
  -ms-flex-pack: center;
  /* justify-content: center; */
  align-self: center;
}

.checked {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://uploads-ssl.webflow.com/63b371ba462a084a8b16493c/63be21b5508ab6dba366caf0_VHS-Distortion-Texture-Mobile.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .hidden {
  display: none;
} */

.body {
  background-color: #f5f0de;
}

.image {
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: inline-block;
  max-width: 19vw;
  margin-top: 4vw;
  margin-left: -2vw;
  padding-left: 0vw;
}

.back {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 20px;
  padding-right: 10px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  text-transform: none;
}

@media screen and (max-width: 991px) {
  h2 {
    font-size: 60px;
    line-height: 72px;
  }

  h6 {
    font-size: 18px;
    line-height: 28px;
  }

  .button-hover {
    background-color: #c5cbe3;
  }

  .button-text {
    color: #22201d;
    font-size: 24px;
  }

  .button-text:hover {
    color: #f7f0dc;
    font-size: 24px;
  }

  .button {
    padding: 16px 20px;
  }

  .contact-form {
    width: 85%;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 10vw;
    line-height: 10vw;
  }

  h6 {
    font-size: 3.5vw;
    line-height: 5vw;
  }

  .button-text {
    color: #22201d;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  .button-text:hover {
    color: #f7f0dc;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  .button {
    width: 100%;
  }

  .letter-bg-color {
    border-style: none;
    border-color: #000;
    background-color: transparent;
    color: #22201d;
  }

  .letter-bg-color.red {
    border-style: solid;
  }

  .letter-bg-color.gold {
    border-style: solid;
    border-width: 3px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .letter-bg-color.blue {
    border-style: solid;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .align-center {
    position: relative;
    z-index: 2;
  }

  .cart {
    margin-right: 6px;
    margin-left: 6px;
  }

  .contact-form {
    width: 100%;
  }

  .contact-content {
    position: relative;
    z-index: 2;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    font-size: 9.5vw;
    line-height: 12vw;
    text-align: center;
  }

  h6 {
    font-size: 5vw;
    line-height: 7vw;
    text-align: center;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .button-text {
    color: #22201d;
    font-size: 24px;
    line-height: 30px;
  }

  .button-text:hover {
    font-size: 24px;
    line-height: 30px;
  }

  .button-text.story:hover {
    font-size: 33px;
  }

  .button-text.story:active {
    font-size: 33px;
  }

  .button {
    padding-right: 9px;
    padding-left: 9px;
  }

  .button._3 {
    background-color: #4056a1;
  }

  .button.discover {
    margin-right: 0vw;
    margin-left: 0vw;
  }

  .letter-bg-color {
    border: 3px solid #000;
    background-color: #f5f0de;
    color: #d79922;
  }

  .letter-bg-color.red {
    padding-right: 2vw;
    padding-left: 3vw;
  }

  .letter-bg-color.red.title {
    z-index: 1;
    display: inline-block;
    padding: 2px 6px 14px 7px;
  }

  .letter-bg-color.gold {
    padding-right: 2px;
    padding-left: 3px;
    border-bottom-left-radius: 70px;
  }

  .letter-bg-color.gold.sign {
    padding-right: 6vw;
    padding-left: 4vw;
  }

  .letter-bg-color.blue {
    padding-right: 10px;
    padding-left: 10px;
  }

  .letter-bg-color.blue.title {
    display: inline-block;
    padding: 7px 8px 9px 5px;
    font-size: 16vw;
    line-height: 16vw;
  }

  .letter-bg-color.blue.dash {
    padding-right: 2vw;
    padding-left: 2vw;
  }

  .letter-bg-color.e {
    padding-right: 2px;
    padding-left: 3px;
  }

  .relative {
    position: relative;
    z-index: 1;
  }

  .image {
    top: 5.1vw;
    display: inline-block;
    max-width: 28vw;
    margin-top: 0vw;
    margin-right: 0px;
    margin-left: -28vw;
  }
}
