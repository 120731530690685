/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c9538a82d2332557-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/94ace5bb2b38a854-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_0f9beb';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Karla_Fallback_0f9beb';src: local("Arial");ascent-override: 89.55%;descent-override: 24.61%;line-gap-override: 0.00%;size-adjust: 102.40%
}.__className_0f9beb {font-family: '__Karla_0f9beb', '__Karla_Fallback_0f9beb'
}.__variable_0f9beb {--font-karla: '__Karla_0f9beb', '__Karla_Fallback_0f9beb'
}

/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c60a942849f5899e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/261413652d32c1c3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/bc39dafafa9e6cfe-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/85933357a759c627-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/87cac5386ae78d2f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2bf82a7bb27b395e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/928f8fbd0ceb6185-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/23d8abc69d62f3d0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0c1e327e026a9f54-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/703d9a90a8aabcb9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/99c7ec355b5ddc3c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/b4561805fb5fcbf1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f1a801f7be0405c3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/2b9ff0c36ea32b8c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a4f00cf9cbfc4a42-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2bb25458ea2620e9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/092b00a2f3798ba2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f378bd2abf9e0d48-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1d769dbdf84e9e05-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/28fac4a6e903645b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/6e5f11352df585dc-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_ba33c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/969699447f100d3e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Titillium_Web_Fallback_ba33c6';src: local("Arial");ascent-override: 119.97%;descent-override: 41.09%;line-gap-override: 0.00%;size-adjust: 94.44%
}.__className_ba33c6 {font-family: '__Titillium_Web_ba33c6', '__Titillium_Web_Fallback_ba33c6'
}.__variable_ba33c6 {--font-titillium-web: '__Titillium_Web_ba33c6', '__Titillium_Web_Fallback_ba33c6'
}

@font-face {
font-family: '__komikax_8f8a8a';
src: url(/_next/static/media/f071c13dcb1912e6-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__komikax_Fallback_8f8a8a';src: local("Arial");ascent-override: 102.51%;descent-override: 18.94%;line-gap-override: 2.23%;size-adjust: 134.62%
}.__className_8f8a8a {font-family: '__komikax_8f8a8a', '__komikax_Fallback_8f8a8a'
}.__variable_8f8a8a {--font-komikax: '__komikax_8f8a8a', '__komikax_Fallback_8f8a8a'
}

.button.w-inline-block {
  display: flex;
  justify-content: center;
}

#flex {
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
  padding-bottom: 10rem;
  justify-content: center;
}

body {
  font-family: var(--font-titillium-web), sans-serif;
  color: #22201d;
  font-size: 14px;
  line-height: 20px;
  background-color: #f5f0de;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 50px;
  line-height: 50px;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: var(--font-karla), sans-serif;
  font-size: 1.5vw;
  line-height: 2vw;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  background-color: transparent;
  font-family: var(--font-karla), sans-serif;
  font-size: 3vh;
  line-height: 3vh;
}

a {
  transition: all 400ms ease;
  color: #f13c20;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  text-decoration: none;
}

a:hover {
  color: #f13c20;
  text-decoration: none;
}

body a:hover {
  text-decoration: none !important;
}

ul {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 40px;
  font-family: var(--font-karla), sans-serif;
  font-size: 16px;
  line-height: 28px;
}

strong {
  font-weight: bold;
}

.button-hover {
  position: absolute;
  left: 0%;
  top: 2%;
  right: 0%;
  bottom: 0%;
  height: 95%;
  border-style: solid;
  border-width: 3px;
  border-color: #22201d;
  border-radius: 2px;
  background-color: #c5cbe3;
  transform: skew(4deg, 0deg);
}

.button-text {
  position: relative;
  z-index: 2;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #22201d;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 3px;
  text-align: center;
  min-width: 200px;
}

.button-text:hover {
  color: #22201d;
}

.button-text.story {
  font-family: var(--font-titillium-web), sans-serif;
  color: #4056a1;
  font-size: 33px;
  font-weight: 900;
  width: 300px;
}

.button {
  background-image: none !important;
  position: relative;
  padding: 18px 30px;
  border-style: solid;
  border-width: 3px;
  border-color: #22201d;
  border-radius: 2px;
  color: #22201d;
  font-size: 16px;
  text-decoration: none;
}

.button:hover {
  color: #22201d;
  text-decoration: none;
}

.button._3 {
  width: 100%;
  justify-content: center;
  background-color: #f13c20;
  transition: all 350ms ease;
  font-family: var(--font-karla), sans-serif;
  color: #f7f0dc;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  text-transform: lowercase;
}

.button._3:hover {
  background-color: #f7f0dc;
  color: #22201d;
}

.button._3.contact-submit {
  position: relative;
  right: 0px;
  display: block;
  width: auto;
  margin-right: 0px;
  margin-left: auto;
  flex: 1;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #4056a1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.button._3.contact-submit:hover {
  background-color: #c5cbe3;
}

.button._3.contact-submit.edit {
  width: auto;
}

.button.discover {
  margin-right: 20vw;
  margin-left: 20vw;
  justify-content: center;
  place-self: center;
}

.letter-bg-color {
  border-style: solid;
  border-width: 3px;
  border-color: #000 #000 #22201d;
  border-top-right-radius: 100px;
  background-color: #f5f0de;
  color: #d79922;
}

.letter-bg-color.red {
  padding-right: 8px;
  padding-left: 18px;
  border-top-left-radius: 100px;
  background-color: #f13c20;
  color: #f5f0de;
}

.letter-bg-color.red.title {
  position: relative;
  z-index: 1;
  padding-right: 10px;
  padding-left: 20px;
}

.letter-bg-color.gold {
  padding-right: 2px;
  padding-left: 9px;
  border-bottom-left-radius: 75px;
  background-color: #d79922;
  color: #f5f0de;
  text-align: left;
}

.letter-bg-color.gold.sign {
  padding-right: 25px;
  padding-left: 19px;
}

.letter-bg-color.blue {
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-right-radius: 100px;
  background-color: #4056a1;
  color: #c5cbe3;
}

.letter-bg-color.blue.title {
  position: relative;
  z-index: 0;
  font-size: 11vw;
  line-height: 11vw;
}

.letter-bg-color.blue.dash {
  padding-right: 13px;
  padding-left: 11px;
}

.letter-bg-color.title {
  padding-right: 10px;
  padding-left: 10px;
}

.align-center {
  text-align: center;
}

.cart {
  margin-right: 30px;
  margin-left: 30px;
  align-self: center;
}

.form-field {
  height: 50px;
  margin-bottom: 18px;
  flex: 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: #22201d;
  border-radius: 2px 25px;
  background-color: #f5f0de;
  font-family: var(--font-karla), sans-serif;
  color: #22201d;
}

.form-field:focus {
  border-color: #d19b52;
}

.contact-form {
  display: block;
  padding-top: 0px;
}

.form-field-wrap {
  display: flex;
  padding-right: 0px;
  font-family: var(--font-karla), sans-serif;
}

.form {
  display: -ms-flexbox;
  /* display: flex; */
  /* -webkit-box-orient: vertical; */
  /* -webkit-box-direction: normal; */
  /* -webkit-flex-direction: column; */
  flex-direction: column;
  /* -webkit-box-pack: center; */
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  /* justify-content: center; */
  flex-wrap: nowrap;
  align-items: flex-start;
}

.contact-content {
  display: flex;
  margin-top: 20px;
  padding-top: 0px;
  align-items: center;
  flex-direction: column;
  /* -webkit-box-pack: center; */
  /* -webkit-justify-content: center; */
  -ms-flex-pack: center;
  /* justify-content: center; */
  align-self: center;
}

.checked {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://uploads-ssl.webflow.com/63b371ba462a084a8b16493c/63be21b5508ab6dba366caf0_VHS-Distortion-Texture-Mobile.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .hidden {
  display: none;
} */

.body {
  background-color: #f5f0de;
}

.image {
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: inline-block;
  max-width: 19vw;
  margin-top: 4vw;
  margin-left: -2vw;
  padding-left: 0vw;
}

.back {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 20px;
  padding-right: 10px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  text-transform: none;
}

@media screen and (max-width: 991px) {
  h2 {
    font-size: 60px;
    line-height: 72px;
  }

  h6 {
    font-size: 18px;
    line-height: 28px;
  }

  .button-hover {
    background-color: #c5cbe3;
  }

  .button-text {
    color: #22201d;
    font-size: 24px;
  }

  .button-text:hover {
    color: #f7f0dc;
    font-size: 24px;
  }

  .button {
    padding: 16px 20px;
  }

  .contact-form {
    width: 85%;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 10vw;
    line-height: 10vw;
  }

  h6 {
    font-size: 3.5vw;
    line-height: 5vw;
  }

  .button-text {
    color: #22201d;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  .button-text:hover {
    color: #f7f0dc;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  .button {
    width: 100%;
  }

  .letter-bg-color {
    border-style: none;
    border-color: #000;
    background-color: transparent;
    color: #22201d;
  }

  .letter-bg-color.red {
    border-style: solid;
  }

  .letter-bg-color.gold {
    border-style: solid;
    border-width: 3px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .letter-bg-color.blue {
    border-style: solid;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .align-center {
    position: relative;
    z-index: 2;
  }

  .cart {
    margin-right: 6px;
    margin-left: 6px;
  }

  .contact-form {
    width: 100%;
  }

  .contact-content {
    position: relative;
    z-index: 2;
    flex-direction: column;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    font-size: 9.5vw;
    line-height: 12vw;
    text-align: center;
  }

  h6 {
    font-size: 5vw;
    line-height: 7vw;
    text-align: center;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .button-text {
    color: #22201d;
    font-size: 24px;
    line-height: 30px;
  }

  .button-text:hover {
    font-size: 24px;
    line-height: 30px;
  }

  .button-text.story:hover {
    font-size: 33px;
  }

  .button-text.story:active {
    font-size: 33px;
  }

  .button {
    padding-right: 9px;
    padding-left: 9px;
  }

  .button._3 {
    background-color: #4056a1;
  }

  .button.discover {
    margin-right: 0vw;
    margin-left: 0vw;
  }

  .letter-bg-color {
    border: 3px solid #000;
    background-color: #f5f0de;
    color: #d79922;
  }

  .letter-bg-color.red {
    padding-right: 2vw;
    padding-left: 3vw;
  }

  .letter-bg-color.red.title {
    z-index: 1;
    display: inline-block;
    padding: 2px 6px 14px 7px;
  }

  .letter-bg-color.gold {
    padding-right: 2px;
    padding-left: 3px;
    border-bottom-left-radius: 70px;
  }

  .letter-bg-color.gold.sign {
    padding-right: 6vw;
    padding-left: 4vw;
  }

  .letter-bg-color.blue {
    padding-right: 10px;
    padding-left: 10px;
  }

  .letter-bg-color.blue.title {
    display: inline-block;
    padding: 7px 8px 9px 5px;
    font-size: 16vw;
    line-height: 16vw;
  }

  .letter-bg-color.blue.dash {
    padding-right: 2vw;
    padding-left: 2vw;
  }

  .letter-bg-color.e {
    padding-right: 2px;
    padding-left: 3px;
  }

  .relative {
    position: relative;
    z-index: 1;
  }

  .image {
    top: 5.1vw;
    display: inline-block;
    max-width: 28vw;
    margin-top: 0vw;
    margin-right: 0px;
    margin-left: -28vw;
  }
}

